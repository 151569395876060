import React from 'react'
import Helmet from 'react-helmet';
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

function ProductMetaData({ name, description, ...props }) {
  const { productMetaData } = props;
  const site = useSiteMetadata()
  return (
    <Helmet>
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': "https://schema.org/",
          '@type': 'Product',
          name: name || site.title || 'Home',
          description: description || site.description,
          review: {
            '@type': 'Review',
            reviewRating: {
              '@type': 'Rating',
              ratingValue: productMetaData?.reviewRatingValue || 5,
              bestRating: productMetaData?.bestRating || 5,
            },
            author: {
              '@type': 'Person',
              name: productMetaData?.reviewAuthor || 'Fred Benson',
            },
          },
          brand: {
            '@type': 'Brand',
            name: 'Together Price',
          },
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: productMetaData?.ratingValue || 4.4,
            reviewCount: productMetaData?.reviewCount || 2388,
          },
        })}
      </script>
    </Helmet>
  );
};

export default ProductMetaData;
